import React, { memo } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { useStaticQuery, graphql } from "gatsby";
import { theme, Container, SectionHeader } from "../constants/theming";
import IntegrationBubble from "./IntegrationBubble";

const StyledContainer = styled(Container)`
  background: ${theme.colors.mediumGray};
`;

const Header = styled(SectionHeader)`
  color: ${theme.colors.white};
  text-align: center;
  max-width: 900px;
`;

const IntegrationContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 64px;
  height: 220px;

  @media ${theme.breakpoints.tablet} {
    height: 410px;
  }

  @media ${theme.breakpoints.laptop} {
    height: 460px;
  }
`;

const bubblePositions = [
  [13.4, 0],
  [51, 1],
  [80, 0],
  [3.8, 28.44],
  [24, 26.666],
  [37.01, 16],
  [48.4, 39.11],
  [64.76, 19.22],
  [76.22, 36],
  [90.01, 23.11],
  [13.47, 58.78],
  [35.07, 62.22],
  [49.02, 75],
  [65.97, 60.44],
  [86.1, 64.666],
];

const Integrations = ({ data }) => {
  const integrationToolsQuery = useStaticQuery(graphql`
    query IntegrationToolsQuery {
      allContentfulSectionContentBlock(
        filter: { uid: { eq: "wavvve-integration-tools-section" } }
      ) {
        edges {
          node {
            blocks {
              ... on ContentfulComponentHeaderAndParagraph {
                uid
                header {
                  json
                }
                paragraph {
                  json
                }
              }
              ... on ContentfulSectionContentBlock {
                uid
                blocks {
                  ... on ContentfulComponentImageAndTextCard {
                    copySection {
                      header {
                        json
                      }
                      paragraph {
                        json
                      }
                    }
                    image {
                      altText
                      asset {
                        title
                        fluid(maxWidth: 120, quality: 90) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    blocks,
  } = integrationToolsQuery.allContentfulSectionContentBlock.edges[0].node;

  const headerData = blocks.find((e) => e.uid === "wavvve-header-integration");
  const integrationData = blocks.find(
    (e) => e.uid === "wavvve-integration-brands"
  );

  return (
    <StyledContainer name="integrations">
      <Header>
        {parse(documentToPlainTextString(headerData.header.json))}
      </Header>
      <IntegrationContainer>
        {integrationData.blocks.map((integration, idx) => (
          <IntegrationBubble
            key={`integration-${idx}`}
            data={integration}
            position={bubblePositions[idx]}
          />
        ))}
      </IntegrationContainer>
    </StyledContainer>
  );
};

export default memo(Integrations);
