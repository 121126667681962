import React, { memo } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { Field, Form } from "formik";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { useStaticQuery, graphql } from "gatsby";
import SignUpForm from "./SignUpForm";
import { theme, Container } from "../constants/theming";

const StyledContainer = styled(Container)`
  background: ${theme.colors.black};
  padding-bottom: 72px;
  justify-content: center;

  @media ${theme.breakpoints.laptop} {
    padding-bottom: 96px;
  }
`;

const Header = styled.h3`
  color: ${theme.colors.white};
  font-size: 28px;
  line-height: 1.2;
  text-align: center;
  margin: 0 auto;
  width: 100%;

  @media ${theme.breakpoints.tablet} {
    font-size: 42px;
  }
`;
const Paragraph = styled.p`
  color: ${theme.colors.lightGray};
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  margin-bottom: 42px;

  @media ${theme.breakpoints.tablet} {
    font-size: 30px;
    margin-bottom: 60px;
  }
`;

const GradientButton = styled.button`
  background: ${theme.gradient.default};
  border-radius: 24px;
  outline: none;
  display: flex;
  font-size: 16px;
  line-height: 1;
  align-items: center;
  width: 200px;
  height: 48px;
  justify-content: center;
  color: ${theme.colors.white};
  border: none;
  cursor: pointer;
  margin-top: 16px;
  overflow: hidden;
  z-index: 1;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${theme.gradient.inverseDefault};
    z-index: -1;
    transition: opacity 0.4s linear;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }
`;

const SignUpFormStyled = styled(SignUpForm)`
  display: flex;
`;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
`;

const StyledInput = styled(Field)`
  background: ${theme.colors.white};
  color: ${theme.colors.black};
  font-size: 15px;
  padding: 0 15px;
  height: 32px;
  border-radius: 4px;
  max-width: 350px;
  width: 90%;

  @media ${theme.breakpoints.tablet} {
    width: 350px;
    max-width: unset;
  }
`;

const EmailFooter = ({ location }) => {
  const emailFooterQuery = useStaticQuery(graphql`
    query emailFooterQuery {
      allContentfulSectionContentBlock(
        filter: { uid: { eq: "wavvve-email-footer-section" } }
      ) {
        edges {
          node {
            blocks {
              ... on ContentfulComponentEmailForm {
                emailDescription {
                  header {
                    json
                  }
                  paragraph {
                    json
                  }
                }
                emailInput {
                  placeholderCopy
                }
                submitButtonCopy
              }
            }
          }
        }
      }
    }
  `);

  const {
    blocks,
  } = emailFooterQuery.allContentfulSectionContentBlock.edges[0].node;
  const { emailDescription, emailInput, submitButtonCopy } = blocks[0];

  return (
    <StyledContainer>
      <Header>
        {parse(documentToPlainTextString(emailDescription.header.json))}
      </Header>
      <Paragraph>
        {parse(documentToPlainTextString(emailDescription.paragraph.json))}
      </Paragraph>
      <SignUpFormStyled formPosition="bottom" location={location}>
        {({ isSubmitting, formName }) => (
          <StyledForm data-netlify={true} name={formName}>
            <Field type="hidden" name="form-name" />
            <Field type="hidden" name="referral" />
            <Field type="hidden" name="incomingReferral" />
            <StyledInput
              placeholder={emailInput.placeholderCopy}
              type="email"
              name="email"
              required
            />
            <GradientButton type="submit" disabled={isSubmitting}>
              {submitButtonCopy}
            </GradientButton>
          </StyledForm>
        )}
      </SignUpFormStyled>
    </StyledContainer>
  );
};

export default memo(EmailFooter);
