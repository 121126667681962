import React, { useState, memo } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import parse from "html-react-parser";
import Modal from "react-modal";
import { MdCheck, MdPlayCircleFilled, MdClose } from "react-icons/md";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { theme, Container } from "../constants/theming";
import { videoActionEvent } from "../helpers/trackers";
import VideoPlayer from "./VideoPlayer";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 10,
  },
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "none",
    right: "auto",
    bottom: "auto",
    width: "90%",
    maxWidth: "800px",
  },
};

const CloseButton = styled.button`
  position: relative;
  background: none;
  border: none;
  margin-left: auto;
  display: block;
  padding: 0;
  cursor: pointer;
`;

const HeroContainer = styled(Container)`
  background: ${theme.colors.black};
`;

const CopyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  position: relative;
  z-index: 2;
  @media ${theme.breakpoints.laptop} {
    margin-top: 50px;
    width: 50%;
  }
  @media ${theme.breakpoints.laptopL} {
    width: 39%;
  }
`;

const StyledHeader = styled.h1`
  color: ${theme.colors.red};
  font-size: 40px;
  white-space: nowrap;
  line-height: 1.2;
  margin: 0;
  span {
    display: block;
    color: ${theme.colors.white};
  }

  @media ${theme.breakpoints.tablet} {
    font-size: min(max(55px, 5.5vw), 74px);
  }
`;

const StyledParagraph = styled.p`
  color: ${theme.colors.lightGray};
  font-size: 20px;
  line-height: 1.5;
  margin: 11px 0 24px;
  font-weight: normal;

  @media ${theme.breakpoints.tablet} {
    font-size: 23px;
    margin: 24px 0 44px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-end;
  position: relative;
  height: 60vw;
  max-height: 500px;
  margin-top: 60px;

  img {
    width: 100%;
  }

  @media ${theme.breakpoints.laptop} {
    max-height: 800px;
    height: auto;
    width: 50%;
    margin-top: 38px;
    margin-top: 0;
    align-self: flex-start;

    img {
      width: calc(100% + 84px) !important;
    }
  }

  @media ${theme.breakpoints.laptopL} {
    width: 61%;
  }
`;

const VideoContainer = styled.div`
  @media ${theme.breakpoints.laptop} {
    width: 95%;
  }
`;

const GradientButton = styled.button`
  background: ${theme.gradient.default};
  border-radius: 28px;
  outline: none;
  display: flex;
  font-size: 19px;
  line-height: 1;
  align-items: center;
  padding: 0 16px;
  width: auto;
  align-self: flex-start;
  height: 56px;
  justify-content: center;
  color: ${theme.colors.white};
  border: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${theme.gradient.inverseDefault};
    z-index: -1;
    transition: opacity 0.4s linear;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 48px;
`;

const StyledListItem = styled.li`
  color: ${theme.colors.white};
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 26px;
  font-size: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${theme.breakpoints.tablet} {
    font-size: 19px;
  }
`;

const Hero = () => {
  const [openModal, setOpenModal] = useState(false);
  const heroQuery = useStaticQuery(graphql`
    query HeroQuery {
      allContentfulSectionContentBlock(
        filter: { uid: { eq: "wavvve-hero-section" } }
      ) {
        edges {
          node {
            blocks {
              ... on ContentfulComponentHero {
                header {
                  json
                }
                subHeader {
                  json
                }
                heroImage {
                  altText
                  asset {
                    title
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                  }
                }
                cta {
                  link
                  copy
                }
                additionalSections {
                  listItems
                }
              }
            }
          }
        }
      }
    }
  `);

  const { blocks } = heroQuery.allContentfulSectionContentBlock.edges[0].node;

  const { additionalSections, cta, header, heroImage, subHeader } = blocks[0];

  const { listItems } = additionalSections[0];

  const handleOpenModal = () => {
    setOpenModal(true);
    disableBodyScroll();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    enableBodyScroll();
  };

  const handleVideoClick = () => {
    handleOpenModal();
    videoActionEvent();
  };

  return (
    <HeroContainer>
      <CopyContainer>
        <StyledHeader>
          {parse(documentToPlainTextString(header.json))}
        </StyledHeader>
        <StyledParagraph>
          {parse(documentToPlainTextString(subHeader.json))}
        </StyledParagraph>
        <VideoContainer>
          <VideoPlayer url={cta.link} />
        </VideoContainer>
        <StyledList>
          {listItems.map((item, idx) => (
            <StyledListItem key={`hero-list-item-${idx}`}>
              <MdCheck
                size={26}
                color={theme.colors.orange}
                style={{
                  paddingRight: "16px",
                  minWidth: "26px",
                }}
              />{" "}
              {item}
            </StyledListItem>
          ))}
        </StyledList>
      </CopyContainer>
      <ImageContainer>
        <Img
          title={heroImage.asset.title}
          style={{ overflow: "visible", width: "100%" }}
          imgStyle={{
            objectFit: "cover",
            objectPosition: "top",
            height: "auto",
          }}
          fluid={heroImage.asset.fluid}
          alt={heroImage.altText}
        />
      </ImageContainer>
    </HeroContainer>
  );
};

export default memo(Hero);
