import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import {
  incomingReferralEvent,
  incomingFacebookReferral,
  incomingLinkedinReferral,
  scrollEvent,
  videoEmbed,
} from "../helpers/trackers";
import { queryHandler, validIncomingReferral } from "../helpers/functions";
import Layout from "../components/layout/Layout";
import Hero from "../components/Hero";
import RiverLayout from "../components/RiverLayout";
import HowItWorks from "../components/HowItWorks";
import Integrations from "../components/Integrations";
import WhyUs from "../components/WhyUs";
import EmailFooter from "../components/EmailFooter";
import UnderConstruction from "../components/UnderConstruction";

const IndexPage = ({ data, location }) => {
  const [scrolledDistance, setScrolledDistance] = useState(0);
  const scrollPercentLandmark = useRef(0);
  const documentHeight = useRef(0);
  const windowHeight = useRef(0);
  const { ref: incomingReferral, fbclid, li_fat_id } = queryHandler({
    location,
  });

  const {
    landingPage: { sections },
  } = data.contentfulSiteSiteContainer;

  const scrollHandler = () => setScrolledDistance(window.scrollY);

  useLayoutEffect(() => {
    documentHeight.current = document.body.offsetHeight;
    windowHeight.current = window.innerHeight;

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  useEffect(() => {
    videoEmbed();
  }, []);

  useEffect(() => {
    if (validIncomingReferral({ incomingReferral })) {
      incomingReferralEvent({ incomingReferral });
    }

    if (fbclid) {
      incomingFacebookReferral({ id: fbclid });
    }

    if (li_fat_id) {
      incomingLinkedinReferral({ id: li_fat_id });
    }
  }, [incomingReferral, fbclid, li_fat_id]);

  useEffect(() => {
    const documentScrolledThreshold =
      scrollPercentLandmark.current * documentHeight.current;

    if (scrolledDistance + windowHeight.current >= documentScrolledThreshold) {
      const percentage = `${
        parseFloat(scrollPercentLandmark.current.toFixed(1)) * 100
      }%`;
      scrollEvent({ percentage });
      scrollPercentLandmark.current = parseFloat(
        (scrollPercentLandmark.current += 0.1).toFixed(1)
      );
    }
  }, [scrolledDistance]);

  return (
    <UnderConstruction />
    // <Layout location={location}>
    //   {sections.map((data) => {
    //     switch (data.uid) {
    //       case "wavvve-hero-section":
    //         return <Hero key="hero-section" />;
    //       case "wavvve-river-layout-section":
    //         return <RiverLayout key="river-layout-section" />;
    //       case "wavvve-how-it-works-section":
    //         return <HowItWorks key="how-it-works-section" />;
    //       case "wavvve-integration-tools-section":
    //         return <Integrations key="integrations-section" />;
    //       case "wavvve-why-make-it-section":
    //         return <WhyUs key="why-us-section" />;
    //       case "wavvve-email-footer-section":
    //         return (
    //           <EmailFooter key="email-footer-section" location={location} />
    //         );
    //       default:
    //         return null;
    //     }
    //   })}
    // </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query LandingPageQuery {
    contentfulSiteSiteContainer(siteTitle: { eq: "Wavvve" }) {
      uid
      landingPage {
        uid
        sections {
          uid
        }
      }
    }
  }
`;
