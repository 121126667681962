import React, { memo } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { theme, Container, SectionHeader } from "../constants/theming";

const StyledContainer = styled(Container)`
  background: #000;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  @media ${theme.breakpoints.laptop} {
    width: 40%;
  }
`;

const Header = styled(SectionHeader)`
  color: ${theme.colors.white};
  margin: 0;
  span {
    color: ${theme.colors.red};
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 64px;
  @media ${theme.breakpoints.laptop} {
    margin-top: 0;
    width: 60%;
  }
`;

const Block = styled.div`
  width: 100%;
  margin-bottom: 32px;
  @media ${theme.breakpoints.tablet} {
    width: calc(50% - 48px);
    margin-right: 48px;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    &:nth-of-type(even) {
      margin-right: 0;
    }
  }
  @media ${theme.breakpoints.laptop} {
    width: calc(50% - 96px);
    margin-left: 96px;
    margin-right: 0;
    margin-bottom: 64px;
  }
`;

const BlockHeader = styled.h3`
  color: ${theme.colors.white};
  font-size: 16px;
  line-height: 1.2;
  margin: 18px 0 8px;

  @media ${theme.breakpoints.tablet} {
    font-size: 18px;
    margin: 32px 0 16px;
  }
`;

const BlockParagraph = styled.p`
  color: ${theme.colors.lightGray};
  font-size: 14px;
  line-height: 1.5;
  margin: 0;

  @media ${theme.breakpoints.tablet} {
    font-size: 16px;
  }
`;

const HowItWorks = () => {
  const howItWorksQuery = useStaticQuery(graphql`
    query HowItWorksQuery {
      allContentfulSectionContentBlock(
        filter: { uid: { eq: "wavvve-how-it-works-section" } }
      ) {
        edges {
          node {
            blocks {
              ... on ContentfulSectionContentBlock {
                blocks {
                  ... on ContentfulComponentImageAndTextCard {
                    image {
                      altText
                      asset {
                        title
                        fluid(maxWidth: 600, quality: 90) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                      }
                    }
                    copySection {
                      header {
                        json
                      }
                      paragraph {
                        json
                      }
                    }
                  }
                }
              }
              ... on ContentfulComponentHeaderAndParagraph {
                header {
                  json
                }
                paragraph {
                  json
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    blocks,
  } = howItWorksQuery.allContentfulSectionContentBlock.edges[0].node;

  const headerData = blocks.find(
    (e) => e.__typename === "ContentfulComponentHeaderAndParagraph"
  );
  const blockData = blocks.find(
    (e) => e.__typename === "ContentfulSectionContentBlock"
  );

  return (
    <StyledContainer name="how-works">
      <HeaderContainer>
        <Header>
          {parse(documentToPlainTextString(headerData.header.json))}
        </Header>
      </HeaderContainer>
      <CardContainer>
        {blockData.blocks.map(({ copySection, image }, idx) => {
          const { header, paragraph } = copySection[0];

          return (
            <Block key={`block-section-${idx}`}>
              {image && (
                <Img
                  title={image.asset.title}
                  style={{
                    boxShadow: "0px 25px 45px -24px rgba(154, 154, 198, 0.45)",
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  fluid={image.asset.fluid}
                  alt={image.altText}
                />
              )}
              <div>
                <BlockHeader>
                  {header && parse(documentToPlainTextString(header.json))}
                </BlockHeader>
                <BlockParagraph>
                  {paragraph &&
                    parse(documentToPlainTextString(paragraph.json))}
                </BlockParagraph>
              </div>
            </Block>
          );
        })}
      </CardContainer>
    </StyledContainer>
  );
};

export default memo(HowItWorks);
