import React, { memo } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { useStaticQuery, graphql } from "gatsby";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { theme, Container, SectionHeader } from "../constants/theming";

const StyledContainer = styled(Container)`
  background: ${theme.colors.black};
  flex-flow: column;
`;

const Header = styled(SectionHeader)`
  color: ${theme.colors.white};
  text-align: center;
  margin-bottom: 24px;
  max-width: 700px;
  width: 100%;

  @media ${theme.breakpoints.tablet} {
    margin-bottom: 48px;
  }
`;

const Paragraph = styled.p`
  color: ${theme.colors.lightGray};
  font-size: 16px;
  line-height: 1.5;
  max-width: 865px;
  width: 100%;
  margin: 0 auto;
  span {
    display: block;
    margin-top: 24px;
  }

  @media ${theme.breakpoints.tablet} {
    font-size: 24px;
    span {
      margin-top: 36px;
    }
  }
`;

const WhyUs = () => {
  const whyUsQuery = useStaticQuery(graphql`
    query WhyUsQuery {
      allContentfulSectionContentBlock(
        filter: { uid: { eq: "wavvve-why-make-it-section" } }
      ) {
        edges {
          node {
            blocks {
              ... on ContentfulComponentHeaderAndParagraph {
                header {
                  json
                }
                paragraph {
                  json
                }
              }
            }
          }
        }
      }
    }
  `);

  const { blocks } = whyUsQuery.allContentfulSectionContentBlock.edges[0].node;

  const { header, paragraph } = blocks[0];
  return (
    <StyledContainer name="why">
      <Header>{parse(documentToPlainTextString(header.json))}</Header>
      <Paragraph>{parse(documentToPlainTextString(paragraph.json))}</Paragraph>
    </StyledContainer>
  );
};

export default memo(WhyUs);
