import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player/vimeo";
import styled from "styled-components";
import { videoWatchedAction, videoPlayEvent } from "../helpers/trackers";

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const VideoPlayer = ({ url }) => {
  const [playedPercent, setPlayedPercent] = useState(0);
  const [percentLandmark, setPercentLandmark] = useState(0.25);
  const videoRef = useRef(null);

  const handleProgress = ({ played }) => {
    if (played > playedPercent) {
      setPlayedPercent(played);
    }
  };

  useEffect(() => {
    if (playedPercent >= percentLandmark) {
      videoWatchedAction({ duration: `${percentLandmark * 100}%`, video: url });
      setPercentLandmark((prevLandmark) => prevLandmark + 0.25);
    }
  }, [playedPercent, percentLandmark, url]);

  const addHotJarAttribute = () => {
    const iframeEl = videoRef.current.querySelector("iframe");
    iframeEl.setAttribute("data-hj-allow-iframe", true);
  };

  return (
    <VideoWrapper ref={videoRef}>
      <StyledReactPlayer
        onPlay={() => videoPlayEvent()}
        onReady={() => addHotJarAttribute()}
        playsinline
        width="100%"
        height="100%"
        onProgress={({ played }) => handleProgress({ played })}
        controls
        data-hj-allow-iframe
        url={url}
      />
    </VideoWrapper>
  );
};

export default VideoPlayer;
