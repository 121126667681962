import React from "react";
import styled, { keyframes } from "styled-components";
import parse from "html-react-parser";
import Img from "gatsby-image";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { theme } from "../constants/theming";

const BIG_LOGOS = ["google meet", "google calendar", "zoom"];

const bubbleFlow = keyframes`
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(10px);
    }
`;

const Bubble = styled.div`
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: ${({ position }) => position[0]}%;
  top: ${({ position }) => position[1]}%;
  width: ${({ big }) => (big ? "10%" : "8%")};
  min-width: ${({ big }) => (big ? "46px" : "34px")};
  animation: ${bubbleFlow} ${() => Math.random() * (6 - 4) + 4}s ease-in-out
    infinite alternate-reverse;

  @media ${theme.breakpoints.tablet} {
    width: ${({ big }) => (big ? "11%" : "9%")};
    min-width: unset;
  }

  @media ${theme.breakpoints.laptop} {
    width: ${({ big }) => (big ? "9%" : "6%")};
    max-width: ${({ big }) => (big ? "92px" : "76px")};
  }
`;

const BubbleCopy = styled.p`
  color: ${theme.colors.lightGray};
  font-size: 16px;
  line-height: 1.2;
  margin: 16px 0 0;
  text-align: center;
  display: none;
  white-space: nowrap;
  position: relative;
  transform: translateX(0);

  @media ${theme.breakpoints.laptop} {
    display: block;
  }
`;

const IntegrationBubble = ({ data: { copySection, image }, position }) => {
  const { header } = copySection[0];
  const company = documentToPlainTextString(header.json);
  const big = BIG_LOGOS.includes(company.toLowerCase());

  return (
    <Bubble big={big} position={position}>
      <Img
        fluid={image.asset.fluid}
        style={{ width: "100%", overflow: "visible" }}
        imgStyle={{
          width: "95%",
          height: "auto",
          borderRadius: "50%",
          padding: "5%",
          background: "rgba(156, 156, 156, 0.17)",
        }}
        title={image.asset.title}
        alt={image.altText}
      />
      <BubbleCopy>{header && parse(company)}</BubbleCopy>
    </Bubble>
  );
};

export default IntegrationBubble;
