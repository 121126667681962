import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import Img from "gatsby-image";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { theme, Container, SectionHeader } from "../constants/theming";

const COLOR_ROTATION = ["purple", "red", "orange"];

const RiverSection = styled(Container)`
  background: ${theme.colors.black};
  padding-bottom: 72px;

  @media ${theme.breakpoints.laptop} {
    padding-bottom: 128px;
  }
`;

const Header = styled(SectionHeader)`
  color: ${theme.colors.white};
  text-align: center;
  span {
    color: ${({ colorBlock }) => theme.colors[colorBlock]};
    display: block;
  }
`;

const TextAndImage = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-flow: column;
  @media ${theme.breakpoints.laptop} {
    margin-top: 96px;
    flex-flow: row;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  background: white;
  order: 1;
  margin-bottom: 16px;
  border-radius: 16px;
  background: ${theme.colors.black};
  @media ${theme.breakpoints.laptop} {
    margin-bottom: 0;
    order: initial;
    width: 60%;
  }
`;

const TextContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  width: 100%;
  padding: 0;
  list-style-type: none;
  order: 2;
  @media ${theme.breakpoints.laptop} {
    order: initial;
    width: 40%;
    margin: ${({ rightAligned }) =>
      rightAligned ? "0 0 0 64px" : "0 64px 0 0"};
  }
`;

const Block = styled.li`
  display: flex;
  flex-wrap: wrap;
  border-left: 4px solid ${({ colorBlock }) => theme.colors[colorBlock]};
  padding-left: 12px;
  margin-bottom: 48px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${theme.breakpoints.tablet} {
    border-left: 6px solid ${({ colorBlock }) => theme.colors[colorBlock]};
    padding-left: 16px;
    margin-bottom: 64px;
  }
`;

const BlockHeader = styled.h3`
  color: ${theme.colors.white};
  width: 100%;
  margin: 0 0 12px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;

  @media ${theme.breakpoints.tablet} {
    margin: 0 0 16px;
    font-size: 20px;
  }
`;

const BlockParagraph = styled.p`
  color: ${theme.colors.lightGray};
  width: 100%;
  line-height: 1.5;
  font-size: 14px;
  margin: 0;

  @media ${theme.breakpoints.tablet} {
    font-size: 16px;
  }
`;

const RiverCard = ({ data, idx }) => {
  const rightAligned = (idx + 1) % 2 !== 0;
  const color = COLOR_ROTATION[idx % COLOR_ROTATION.length];
  const headerData = data.blocks.find(
    (e) => e.__typename === "ContentfulComponentHeaderAndParagraph"
  );
  const listData = data.blocks.find(
    (e) => e.__typename === "ContentfulComponentImageAndTextCard"
  );
  const { image, copySection } = listData;

  const scrollLink = idx === 0 ? "features" : "";

  return (
    <RiverSection name={scrollLink}>
      <Header colorBlock={color}>
        {parse(documentToPlainTextString(headerData.header.json))}
      </Header>
      <TextAndImage>
        {rightAligned ? (
          <>
            <ImageContainer>
              {image && (
                <Img
                  title={image.asset.title}
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  imgStyle={{ objectFit: "contain" }}
                  fluid={image.asset.fluid}
                  alt={image.altText}
                />
              )}
            </ImageContainer>
            <TextContainer rightAligned={rightAligned}>
              {copySection.map(({ header, paragraph }, idx) => (
                <Block colorBlock={color} key={`block-${color}-${idx}`}>
                  <BlockHeader>
                    {header && parse(documentToPlainTextString(header.json))}
                  </BlockHeader>
                  <BlockParagraph>
                    {paragraph &&
                      parse(documentToPlainTextString(paragraph.json))}
                  </BlockParagraph>
                </Block>
              ))}
            </TextContainer>
          </>
        ) : (
          <>
            <TextContainer>
              {copySection.map(({ header, paragraph }, idx) => (
                <Block colorBlock={color} key={`block-${color}-${idx}`}>
                  <BlockHeader>
                    {header && parse(documentToPlainTextString(header.json))}
                  </BlockHeader>
                  <BlockParagraph>
                    {paragraph &&
                      parse(documentToPlainTextString(paragraph.json))}
                  </BlockParagraph>
                </Block>
              ))}
            </TextContainer>
            <ImageContainer>
              {image && (
                <Img
                  title={image.asset.title}
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  imgStyle={{ objectFit: "contain" }}
                  fluid={image.asset.fluid}
                  alt={image.altText}
                />
              )}
            </ImageContainer>
          </>
        )}
      </TextAndImage>
    </RiverSection>
  );
};

export default RiverCard;
